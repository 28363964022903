body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.caseInbox-MuiDrawer-paper {
  top: 50px !important;
  left: 60px !important;
}

.caseInbox-app-container-20 {
  background-image: linear-gradient(to bottom right, #1292B5, #68C4D6);

}

.caseInbox-app-container-23:hover {
  color: #201e45 !important;
}

.caseInbox-app-container-10,
.caseInbox-app-container-11 {
  background-color: #ffffff !important;
}

.form-control:disabled,
.caseInbox-MuiAppBar-colorDefault {
  background-color: #e1f2f5 !important;
}