.sxp_js_chat_chatHeadaer, .sxp_js_chat_chatIcon, .journeyTray, .trayBtn, .sxp_js_chat_closeIcon  {
    background-color: #1292B5 !important;
}
.sxp_js_chat_sxpChat.sxp_js_chat_fullScreen {
    bottom: 0px;
    height: 95%;
}

.sxp_js_chat_chat {
    height: 330px;
}

.sxp_js_chat_title {
    font-size: 20px;
}

.sxp_js_chat_fullScreen .sxp_js_chat_chat {
    height: 400px;
}